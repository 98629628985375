@media (min-width: 768px) {
    html[data-theme*='desktop-dark'],
    div[data-theme*='desktop-dark'] {
        --text-primary: var(--color-brand-white);
        --text-secondary: var(--color-brand-aluminium);
        --text-inactive: var(--color-brand-titanium);
        --text-brand: var(--color-brand-white);
        --text-invert: var(--color-brand-black);

        --surface-primary: var(--color-brand-black);
        --surface-invert: var(--color-brand-white);
        --surface-secondary: var(--color-brand-aluminium);
        --surface-brand: var(--color-brand-white);
        --surface-hover-primary: var(--color-brand-cement);
        --surface-hover-invert: var(--color-brand-titanium);
        --surface-product: var(--color-brand-cement);

        --border-primary: var(--color-brand-white);
        --border-secondary: var(--color-brand-aluminium);
    }

    html[data-theme*='desktop-dark'],
    div[data-theme*='desktop-dark'] {
        color: var(--text-primary);
        background-color: var(--surface-primary);
    }
}

@media (max-width: 767px) {
    html[data-theme*='mobile-dark'],
    div[data-theme*='mobile-dark'] {
        --text-primary: var(--color-brand-white);
        --text-secondary: var(--color-brand-aluminium);
        --text-inactive: var(--color-brand-titanium);
        --text-brand: var(--color-brand-white);
        --text-invert: var(--color-brand-black);

        --surface-primary: var(--color-brand-black);
        --surface-invert: var(--color-brand-white);
        --surface-secondary: var(--color-brand-aluminium);
        --surface-brand: var(--color-brand-white);
        --surface-hover-primary: var(--color-brand-cement);
        --surface-hover-invert: var(--color-brand-titanium);
        --surface-product: var(--color-brand-cement);

        --border-primary: var(--color-brand-white);
        --border-secondary: var(--color-brand-aluminium);
    }

    html[data-theme*='mobile-dark'],
    div[data-theme*='mobile-dark'] {
        color: var(--text-primary);
        background-color: var(--surface-primary);
    }
}
